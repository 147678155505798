.custom-right-arrow {
  position: absolute !important;
  right: 30px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  transform: rotate(-45deg);
}
.custom-right-arrow:hover {
  opacity: 1;
}
.custom-left-arrow {
  position: absolute !important;
  left: 30px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  transform: rotate(135deg);
}
.custom-left-arrow {
  opacity: 1;
}
.container {
  margin-top: 10%;
}
#root {
  padding: 0 80px;
}
.container-with-dots {
  margin-top: 2%;
  padding-bottom: 30px;
}
.custom-button-group {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
}
.container-padding-bottom {
  padding-bottom: 20px;
}
